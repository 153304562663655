import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/(app)/admin": [5,[2]],
		"/(app)/admin/page1": [6,[2]],
		"/(app)/admin/page2": [7,[2]],
		"/(app)/admin/sentry": [~8,[2]],
		"/auth": [13],
		"/auth/google/callback": [14],
		"/auth/logout": [15],
		"/auth/next": [16],
		"/(app)/billing": [9,[2]],
		"/(app)/pricing": [10,[2]],
		"/(app)/youtube": [11,[2,3]],
		"/(app)/youtube/[videoId]": [12,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';