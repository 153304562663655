import settings from '$lib/config-public';
import * as Sentry from '@sentry/sveltekit';
import {
	captureConsoleIntegration,
	handleErrorWithSentry,
	replayIntegration
} from '@sentry/sveltekit';

let handleErr;

if (settings.PUBLIC_SENTRY_DSN) {
	Sentry.init({
		dsn: settings.PUBLIC_SENTRY_DSN,
		tunnel: '/sentry-tunnel',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [replayIntegration(), captureConsoleIntegration({ levels: ['error'] })]
	});

	// If you have a custom error handler, pass it to `handleErrorWithSentry`
	handleErr = handleErrorWithSentry();
} else {
	handleErr = ({ event, error }) => {
		console.error('Error:', error);
		console.error('Stack:', error.stack);
		// setTimeout(() => {
		// 	isLoading.current = false;
		// }, 100);
	};
}

export const handleError = handleErr;
